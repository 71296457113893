import { Controller } from 'stimulus';

/*
 * Subcategories form managing
 *
 */

export default class extends Controller {

	static targets = [];

  subcategories_number = 0;
  add_subcategory_button_container = null;
  new_subcategory_button = null;

	connect () {
    this.add_subcategory_button_container = document.querySelector('.add-subcategory-button-container');
    this.add_subcategory_button_container.classList.remove('border-t', 'border-gray-200', 'pt-4')

    this.new_subcategory_button = document.getElementById('newSubcategoryButton');

    this.new_subcategory_button.addEventListener('click', (event) => {
      this.addSubcategory();
    });
  }

  addSubcategory () {

    this.add_subcategory_button_container.classList.add('border-t', 'border-gray-200', 'pt-4');

    let mSec = new Date().getTime();

    let idAttrNameES = 'category_subcategories_attributes_0_name_es'.replace('0', mSec);
    let nameAttrNameES = 'category[subcategories_attributes][0][name_es]'.replace('0', mSec);

    let idAttrNameEN = 'category_subcategories_attributes_0_name_en'.replace('0', mSec);
    let nameAttrNameEN = 'category[subcategories_attributes][0][name_en]'.replace('0', mSec);

    let idAttrNameDE = 'category_subcategories_attributes_0_name_de'.replace('0', mSec);
    let nameAttrNameDE = 'category[subcategories_attributes][0][name_de]'.replace('0', mSec);

    let idAttrNameFR = 'category_subcategories_attributes_0_name_fr'.replace('0', mSec);
    let nameAttrNameFR = 'category[subcategories_attributes][0][name_fr]'.replace('0', mSec);

    let li = document.createElement('li');
    let div1 = document.createElement('div');
    let div2 = document.createElement('div');
    let div3 = document.createElement('div');
    let div4 = document.createElement('div');
    let div_picture = document.createElement('div');

    let p = document.createElement('p');

    div1.setAttribute('class', 'max-w-lg flex flex-col mb-8');
    div2.setAttribute('class', 'mb-2 flex flex-row justify-between');
    div3.setAttribute('class', 'max-w-lg flex flex-col mb-8');
    
    p.setAttribute('class', 'text-sm text-gray-400');
    p.innerHTML = 'Subcategory ' + (this.subcategories_number += 1);

    div2.appendChild(p);
    div4.innerHTML = `
      <a class="cursor-pointer" data-action="click->subcategories-form#removeSubcategory">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
      </a>
    `;
    div2.appendChild(div4);
    div3.appendChild(div2);

    div_picture.setAttribute('class', 'flex');
    div_picture.innerHTML = `
      <div class="border-2 w-full border-gray-300 border-dashed rounded-md text-center space-y-1 p-5 text-gray-400 cursor-pointer dropzone dropzone-default dz-clickable" data-controller="dropzone" data-dropzone-max-file-size="2" data-dropzone-max-files="1" data-dropzone-accepted-files="image/png,image/jpeg" data-dropzone-target="dropzone">
        <input multiple="multiple" data-dropzone-target="input" id="pictureSubcategory__subcategory__Block" data-direct-upload-url="/rails/active_storage/direct_uploads" type="file" name="category[subcategories_attributes][__subcategory__][picture]" disabled="" style="display: none;">
        <div class="dropzone-msg dz-message needsclick font-weight-light" data-dropzone-target="dropzoneMsg">
          <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div class="text-sm">
            Upload a file or drag and drop
          </div>
          <div class="text-xs">
            <span>PDF, DOC up to 2MB</span>
          </div>
        </div>
        <div class="dz-error-custom alert alert-danger animated fadeIn" data-dropzone-target="dropzoneError"></div>
      </div>
    `.replaceAll('__subcategory__', mSec);

    div3.appendChild(div_picture);


    let inputNameES = document.createElement('input');
    let divNameES = document.createElement('div');

    inputNameES.setAttribute('class', 'flex-1 form-control info');
    inputNameES.setAttribute('type', 'text');
    inputNameES.setAttribute('placeholder', 'ES');
    inputNameES.setAttribute('required', '');
    inputNameES.setAttribute('id', idAttrNameES);
    inputNameES.setAttribute('name', nameAttrNameES);

    divNameES.setAttribute('class', 'flex rounded-md shadow-sm mt-4');
    divNameES.appendChild(inputNameES);
    div3.appendChild(divNameES);


    let inputNameEN = document.createElement('input');
    let divNameEN = document.createElement('div');
    
    inputNameEN.setAttribute('class', 'flex-1 form-control info');
    inputNameEN.setAttribute('type', 'text');
    inputNameEN.setAttribute('placeholder', 'EN');
    inputNameEN.setAttribute('required', '');
    inputNameEN.setAttribute('id', idAttrNameEN);
    inputNameEN.setAttribute('name', nameAttrNameEN);

    divNameEN.setAttribute('class', 'flex rounded-md shadow-sm mt-4');
    divNameEN.appendChild(inputNameEN);
    div3.appendChild(divNameEN);


    let inputNameDE = document.createElement('input');
    let divNameDE = document.createElement('div');

    inputNameDE.setAttribute('class', 'flex-1 form-control info');
    inputNameDE.setAttribute('type', 'text');
    inputNameDE.setAttribute('placeholder', 'DE');
    inputNameDE.setAttribute('required', '');
    inputNameDE.setAttribute('id', idAttrNameDE);
    inputNameDE.setAttribute('name', nameAttrNameDE);

    divNameDE.setAttribute('class', 'flex rounded-md shadow-sm mt-4');
    divNameDE.appendChild(inputNameDE);
    div3.appendChild(divNameDE);

    let inputNameFR = document.createElement('input');
    let divNameFR = document.createElement('div');

    inputNameFR.setAttribute('class', 'flex-1 form-control info');
    inputNameFR.setAttribute('type', 'text');
    inputNameFR.setAttribute('placeholder', 'FR');
    inputNameFR.setAttribute('required', '');
    inputNameFR.setAttribute('id', idAttrNameFR);
    inputNameFR.setAttribute('name', nameAttrNameFR);

    divNameFR.setAttribute('class', 'flex rounded-md shadow-sm mt-4');
    divNameFR.appendChild(inputNameFR);
    div3.appendChild(divNameFR);

    li.appendChild(div3);

    document
      .getElementById('subcategories_list')
      .appendChild(li);
  }


  removeSubcategory (event) {
    
    let action = event.target.closest('li').dataset['action'];
    let index = event.target.closest('li').dataset['index'];

    event.target.closest('li').remove();

    // Used when trying to remove a subcategory when
    // editing a category. We need to insert an input
    // hidden tag with name "_destroy" and value "true"
    // to tell Rails to delete the given subcategory
    if ( action === 'edit' ) {

      let subcategoryToDeleteName = 'category[subcategories_attributes][0][_destroy]'.replace('0', index);
      let subcategoryToDeleteId = 'category_subcategories_attributes_0__destroy'.replace('0', index);

      let input = document.createElement('input');

      input.setAttribute('type', 'hidden');
      input.setAttribute('name', subcategoryToDeleteName);
      input.setAttribute('id', subcategoryToDeleteId);
      input.setAttribute('value', true);

      document.getElementById('edit_ingredient').appendChild(input);
    }
  }
}