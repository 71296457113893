import { Controller } from 'stimulus';

/*
 * Toggle switch controller
 * Manages 3 positions toggle switch logic
 *
 */

export default class extends Controller {

	static targets = [ 'text' ];

	connect () {
    
  }

  updateText (event) {
    if ( event.target.className.indexOf('switch1') > -1 )
      this.textTarget.innerHTML = 'No contain';

    if ( event.target.className.indexOf('switch2') > -1 )
      this.textTarget.innerHTML = 'May contain';

    if ( event.target.className.indexOf('switch3') > -1 )
      this.textTarget.innerHTML = 'Contain';
  }

}