import { Controller } from 'stimulus';

/*
 * Modals state managing
 *
 */

export default class extends Controller {

	static targets = [];

  // For modals with form and submit
  form = null;
  submit = null;

	connect () {

    this.content = this.element.querySelector('[id*="_modal"]');
    
    if ( this.content ) {

      // Checking if there's a form
      this.form = this.content.querySelector('form');

      if ( this.form )
        this.submit = this.element.querySelector('input[type="submit"]');
    }

    if ( this.form ) {
      this.form.addEventListener('turbo:submit-end', (event) => {
        if ( event.detail.formSubmission.result.success ) {
          this.close();
          this.restoreForm();
        }
      });
    }
  }

  // Open modal
  open (event) {

    let modalContent = document.getElementById(event.currentTarget.dataset.modal + '_modal');

    modalContent
      .closest('#modal')
      .classList
      .remove('hidden');

    // If there's a data-id attribute in click handler we pass the id
    // to the modal
    if ( event.currentTarget.dataset['modalId'] )
      modalContent.setAttribute('data-parent-id', event.currentTarget.dataset['modalId']);
  }

  // Close modal
  close () { this.element.classList.add('hidden'); }

  // Restore form after a successful response
  restoreForm () {
    this.form.reset();

    if ( this.submit ) {
      this.submit.disabled = false;
      this.submit.value = 'Create'
    }
  }
}