import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import Sortable from "sortablejs"

export default class extends Controller {

  frontends = [];

  static targets = [
    'alert',
    'table',
    'body'
  ];

  static values = {
    action: String,
    frontendsNumber: Number
  };

  connect() {
    let container = document.getElementById('sortable');
    this.sortable = Sortable.create(container, {
      onEnd: this.end.bind(this)
    });
    if ( this.actionValue === 'create' ) {

      // We're creating a new frontend
      // We hide the table until we add the first frontend
      this.tableTarget.classList.add('hidden');
    }
    else {
      
      // We're editing a frontend
      if ( this.frontendsNumberValue > 0 ) {
        this.alertTarget.classList.add('hidden');

        // Creating frontends list at startup
        this._fillFrontendsList(document.querySelectorAll('tr[id*="tr-frontend-"]'));
      }
      else {
        this.tableTarget.classList.add('hidden');
      }
    }
  }

  end(event) {
    let id = event.item.dataset.id;
    let url = `/app/frontends/${id}/move`;
    let data = new FormData();
    data.append('position', event.newIndex + 1);
    Rails.ajax({
      url: url,
      type: 'PATCH',
      data: data
    })
  }


  /*
   * addFrontend
   * Adds a new frontend to the list.
   * Creates a row in the table.
   * 
   */

  addFrontend (event) {

    let frontend_id = event.target.dataset['id'];

    if ( this._checkRepeatedFrontend(frontend_id) ) return;

    this.frontends.push({
      id: frontend_id,
      url: event.target.dataset['url'],
      name: document.getElementById('frontend-' + frontend_id + '-name').innerText
    });

    this._createRowForTable(this.frontends[this.frontends.length - 1]);

    if ( this.frontends.length > 0 ) {
      this.alertTarget.classList.add('hidden');
      this.tableTarget.classList.remove('hidden');
    }

  }


  /*
   * removeFrontend
   * Removes a frontend from the list
   * 
   */

  removeFrontend (event) {

    let frontend_id = event.currentTarget.dataset['id'];

    // Removing from frontends array
    const frontendIndex = this.frontends.findIndex(frontend => frontend.id === frontend_id);

    this.frontends.splice(frontendIndex, 1);

    // Removing from table
    document.getElementById('tr-frontend-' + frontend_id).remove();

    // Hiding table and showing alert
    if ( this.frontends.length === 0 ) {
      this.tableTarget.classList.add('hidden');
      this.alertTarget.classList.remove('hidden');
    }
  }


  /*
   * _createRowForTable
   * Creates a new row in the table with the frontend info
   * 
   */

  _createRowForTable (frontend) {

    let tr = document.createElement('tr');
    let td1 = document.createElement('td');
    let td2 = document.createElement('td');
    let td3 = document.createElement('td');

    let input = document.createElement('input');
    
    tr.setAttribute('id', 'tr-frontend-' + frontend.id);

    td1.setAttribute('class', 'px-6 py-4');
    td1.innerHTML = `
      <div style="cursor: grab">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="border rounded-md px-1 py-1 w-7 h-7">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
        </svg>
      </div>`;
    
    td2.setAttribute('class', 'px-6 py-4 whitespace-nowrap');

    td2.innerHTML = `
      <div class="flex items-center">
        <div class="text-sm font-medium text-gray-900">
          <span class="font-bold">
            <a href="${frontend.url}" target="_blank">
              ${frontend.name}                
            </a>
          </span>
        </div>
      </div>
    `;

    td3.setAttribute('class', 'px-6 py-4 whitespace-nowrap flex justify-end');
    td3.innerHTML = `
      <button type="button" class="btn" data-id="${frontend.id}" data-action="click->frontends-list#removeFrontend">
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
        </svg>
      </button>
    `;

    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'frontend[frontend_ids][]');
    input.setAttribute('value', frontend.id);
    input.setAttribute('id', `frontend_frontend_ids_${frontend.id}`);

    tr.appendChild(input);
    tr.appendChild(td1);
    tr.appendChild(td2);
    tr.appendChild(td3);

    this.bodyTarget.appendChild(tr);
  }


  /*
   * _fillFrontendsList
   * When editing a frontend, we need to load the
   * frontends list in the beginning.
   *
   */

  _fillFrontendsList (frontendsTableTows) {
    frontendsTableTows.forEach((row) => {
      let frontend_id = row.dataset['id'];

      this.frontends.push({
        id: frontend_id,
        url: row.dataset['url'],
        name: row.dataset['name']
      });
    });
  }


  /*
   * _checkRepeatedFrontend
   * Returns if the given frontend is already
   * stored in the frontends list
   * 
   */

  _checkRepeatedFrontend (frontend_id) {
    for (let i = 0, j = this.frontends.length; i < j; i += 1) {
      if ( this.frontends[i].id === frontend_id )
        return true
    }

    return false;
  }
}
