import { Controller } from 'stimulus';
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {

  connect () {
    this.setLang();
  }

  setLang (event) {
    let lang;
    let tmp = sessionStorage.getItem('lang');

    // If this was triggered by selecting a language, set that language
    if ( event )
      lang = event.target.value;
    // Otherwise, if there is a language in sessionStorage, use that
    // Otherwise, use the default language
    else {
      if ( !tmp )
        lang = document.getElementById('language').value;
      else
        lang = tmp;
    }

    // Set the language in sessionStorage
    sessionStorage.setItem('lang', lang);

    let titles =  document.querySelectorAll('.lang-title');
    titles.forEach(title => {
      title.classList.add('hidden', );
    });
    document.getElementById('t'+lang).classList.remove('hidden');

    let blocks = document.querySelectorAll('.lang-block');
    blocks.forEach(block => {
      block.classList.add('hidden', 'w-0');
    });

    document.getElementById('language').value = lang;
    document.getElementById(lang).classList.remove('hidden', 'w-0');
  }

}