import { Controller } from 'stimulus';

/*
 * Modals state managing
 *
 */

export default class extends Controller {

	static targets = [];

	connect () {}

  // Open modal
  open (event) {

    let modalContent = document.getElementById(event.currentTarget.dataset.modal + '_modal');

    modalContent
      .closest('#modal')
      .classList
      .remove('hidden');

    // If there's a data-id attribute in click handler we pass the id
    // to the modal
    let parent_id = null;

    if ( event.currentTarget.dataset['modalId'] ) {
      parent_id = event.currentTarget.dataset['modalId'];
      
      let saveButton = document.getElementById('grossAmountNotesSaveButton');
          saveButton.setAttribute('data-parent-id', parent_id);
      
      modalContent.setAttribute('data-parent-id', parent_id);
    }

    // Modifying inputs with the parent id
    let grossAmountNoteHiddenInput = document.getElementById('dish_ingredients_lists_attributes_id_row_gross_amount_note'.replace('id_row', parent_id));
    let grossAmountUnitHiddenInput = document.getElementById('dish_ingredients_lists_attributes_id_row_gross_amount_unit'.replace('id_row', parent_id));

    let grossAmountNoteInput = document.getElementById('gross_amount_note');
    let grossAmountUnitInput = document.getElementById('gross_amount_unit');
    
    grossAmountNoteInput.value = grossAmountNoteHiddenInput.value;
    grossAmountUnitInput.value = grossAmountUnitHiddenInput.value;
  }

  // Close modal
  close (event) {

    let parent_id = event.currentTarget.dataset['parentId'];

    let grossAmountNoteHiddenInput = document.getElementById('dish_ingredients_lists_attributes_id_row_gross_amount_note'.replace('id_row', parent_id));
    let grossAmountUnitHiddenInput = document.getElementById('dish_ingredients_lists_attributes_id_row_gross_amount_unit'.replace('id_row', parent_id));

    let grossAmountNoteInput = document.getElementById('gross_amount_note');
    let grossAmountUnitInput = document.getElementById('gross_amount_unit');

    grossAmountNoteHiddenInput.value = grossAmountNoteInput.value;
    grossAmountUnitHiddenInput.value = grossAmountUnitInput.value;


    //
    // Changing create note button color if there's
    // grossAmountNote or grossAmountUnit at least
    //
    // When there's note, button is blue
    // Otherwise, it's white.
    //

    let createNoteButton = document.getElementById('create_note_' + parent_id);

    if ( grossAmountNoteInput.value || grossAmountUnitInput.value )
      createNoteButton.classList.add('btn-info');
    else
      createNoteButton.classList.remove('btn-info');


    //
    // Updating text below inputs in table
    //

    let textNoteSpan = document.getElementById('text_note_' + parent_id);

    textNoteSpan.innerHTML = `${grossAmountNoteInput.value} ${grossAmountUnitInput.value}`


    // Reset values
    grossAmountNoteInput.value = undefined;
    grossAmountUnitInput.value = undefined;

    this.element
      .closest('#modal')
      .classList
      .add('hidden');
  }

}