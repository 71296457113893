import { Controller } from 'stimulus';

/*
 * Admin Finder Field controller
 * Let users to search entities in dashboard.
 *
 */

export default class extends Controller {

	static targets = [
		'form',
		'input',
		'submit'
	];

	// To avoid concanated searches 
	lastSearchValue = null;


	connect () {

		//
		// When Turbo request starts
		//

		this.formTarget.addEventListener('turbo:submit-start', () => {
			this.submitTarget.disabled = true;
			this.submitTarget.innerHTML = `
				<svg class='animate-spin h-5 w-5 text-blue-500' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
	        <circle class='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' stroke-width='4'></circle>
	        <path class='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
	      </svg>
			`;
		});


		//
		// When Turbo request finishes
		//

		this.formTarget.addEventListener('turbo:submit-end', () => {
			setTimeout(() => {
				this.submitTarget.disabled = false;
				this.submitTarget.innerHTML = 'Search';
			}, 500);
		});
	}


	/*
	 * update
	 * Send string to backend on user's typing
	 *
	 */

	update (event) {
		if ( this.inputTarget.value.length > 3 || this.inputTarget.value.length === 0 ) {
			setTimeout(() => {				
				if ( this.lastSearchValue === this.inputTarget.value )
					return;
				else {
					this.lastSearchValue = this.inputTarget.value;
					this.submitTarget.click();
				}
			}, 700);
		}
	}


	/*
	 * update
	 * Send string to backend on user's typing
	 *
	 */

	updateWithEnter (event) {
		console.log('ENTER');
	}

}