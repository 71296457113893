import { Controller } from "stimulus"

export default class extends Controller {

  connect() {

    const query = window.location.search;
    const urlParams = new URLSearchParams(query)
    let section = (urlParams.get("section"))
    let element = document.getElementById(section)
    
    if (element && !element.classList.contains("information_no_selected")){
        element = document.getElementById(section + "m")
    }

    if(element != null) {
        element.click()
        window.scrollTo(0, document.getElementById("information_section").offsetTop);
        if (!element.classList.contains("information_selected")) {
            window.scrollTo(0, element.offsetTop);
        }     
    }

  }

  showInfo(event) {
    let e = event.target 
    let selected = document.getElementsByClassName("information_selected")[0]
    let newSelected = document.getElementById(e.id)
    let actualId = selected.dataset.id
    let newId = newSelected.id
    let actualInfo = document.getElementById("information-" + actualId.substring(actualId.length-1))
    let newInfo = document.getElementById("information-" + newId.substring(newId.length-1))

    selected.classList.remove('information_selected')
    selected.classList.add('information_no_selected')
    newSelected.classList.remove('information_no_selected')
    newSelected.classList.add('information_selected')
    actualInfo.classList.add('hidden')
    newInfo.classList.remove('hidden')
    
  }

  mobileinfo(event){
    let e = event.target
    let information = document.getElementById(e.dataset.id)
    let icons = document.getElementsByClassName(e.dataset.id)
    let previousInfo = document.getElementsByClassName("information_mobile_selected")[0]
    let previousIcons;

    //Change texts and information
    if(e == previousInfo){
      previousInfo.classList.remove("information_mobile_selected")
      previousInfo.classList.add("information_mobile_no_selected")
      information.classList.add("hidden")
    } else if(previousInfo == undefined) {
      e.classList.remove("information_mobile_no_selected")
      e.classList.add("information_mobile_selected")
      information.classList.remove("hidden")
    } else {
      previousInfo.classList.remove("information_mobile_selected")
      previousInfo.classList.add("information_mobile_no_selected")
      e.classList.remove("information_mobile_no_selected")
      e.classList.add("information_mobile_selected")
      information.classList.remove("hidden")
      previousIcons = document.getElementsByClassName(previousInfo.dataset.id)
      document.getElementById(previousInfo.dataset.id).classList.add("hidden")
    }

    //Change icon >
    for(var i = 0; i<icons.length; i++){
      if (icons[i].classList.contains("hidden")){
        icons[i].classList.remove("hidden")
      } else {
        icons[i].classList.add("hidden")
      }
    }
    if(previousInfo != undefined && e != previousInfo){
      for(var i = 0; i<previousIcons.length; i++){
        if (previousIcons[i].classList.contains("hidden")){
          previousIcons[i].classList.remove("hidden")
        } else {
          previousIcons[i].classList.add("hidden")
        }
      }
    }
    
  }


}
