import { Controller } from 'stimulus';
import * as Turbo from "@hotwired/turbo"

/*
 * AJAX finder for ingredients in dish creation
 *
 */

export default class extends Controller {

	static targets = [ 'input', 'button', 'check' ];

	static values = {
		url: String
	};

	// dishes/ingredients

	// To avoid concanated searches 
	lastSearchValue = null;
  url = '/app/:uri?q[name_es_or_name_en_or_name_fr_or_name_de_cont]=query';

	connect () {

		this.url = this.url.replace(':uri', this.urlValue);

		//
		// When Turbo request starts
		//

		document.addEventListener('turbo:before-visit', (event) => {
			this.buttonTarget.disabled = true;
			this.buttonTarget.innerHTML = `
				<svg class='animate-spin h-5 w-5 text-blue-500' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
	        <circle class='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' stroke-width='4'></circle>
	        <path class='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
	      </svg>
			`;
		});


		//
		// When Turbo request finishes
		//

		document.addEventListener('turbo:visit', () => {
			setTimeout(() => {
				this.buttonTarget.disabled = false;
				this.buttonTarget.innerHTML = 'Search';
			}, 500);
		});

	}


	/*
	 * update
	 * Send string to backend on user's typing
	 *
	 */

	update (event) {
		if ( this.inputTarget.value.length > 3 || this.inputTarget.value.length === 0 ) {
			setTimeout(() => {				
				if ( this.lastSearchValue === this.inputTarget.value )
					return;
				else {
					this.lastSearchValue = this.inputTarget.value;

          Turbo.visit(this.url.replace('query', this.inputTarget.value), { action: 'replace' })
				}
			}, 700);
		}
	}

  search (event) {
    Turbo.visit(this.url.replace('query', this.inputTarget.value), { action: 'replace' });
  }

  searchWithEnter (event) {
		if ( event.keyCode === 13 ) {
			event.preventDefault();
			event.stopPropagation();
			this.search();
		}
  }

	// Restrict the search to the users ingredients
	// Only used for dishes
  checkMyIngredients (event) {
    if ( event.target.checked )
      this.url = this.url + '&show=my-ingredients';
    else
      this.url = this.url.replace('&show=my-ingredients', '');

    this.search();
  }

}