import { Controller } from "stimulus"

export default class extends Controller {

  dishes = [];

  static targets = [
    'alert',
    'table',
    'body'
  ];

  static values = {
    action: String,
    dishesNumber: Number
  };

  connect() {

    if ( this.actionValue === 'create' ) {

      // We're creating a new menu
      // We hide the table until we add the first dish
      this.tableTarget.classList.add('hidden');
    }
    else {
      // We're editing a dish
      if ( this.dishesNumberValue > 0 ) {
        this.alertTarget.classList.add('hidden');

        // Creating dishes list at startup
        this._fillDishesList(document.querySelectorAll('tr[id*="tr-dish-"]'));
      }
      else {
        this.tableTarget.classList.add('hidden');
      }
    }
  }


  /*
   * addDish
   * Adds a new dish to the list.
   * Creates a row in the table.
   * 
   */

  addDish (event) {

    let dish_id = event.target.dataset['id'];

    if ( this._checkRepeatedDish(dish_id) ) return;

    this.dishes.push({
      id: dish_id,
      image: event.target.dataset['image'],
      url: event.target.dataset['url'],
      name_en: document.getElementById('dish-' + dish_id + '-name-en').innerText
    });

    this._createRowForTable(this.dishes[this.dishes.length - 1]);

    if ( this.dishes.length > 0 ) {
      this.alertTarget.classList.add('hidden');
      this.tableTarget.classList.remove('hidden');
    }

  }


  /*
   * removeDish
   * Removes an ingredient from the list
   * 
   */

  removeDish (event) {

    let dish_id = event.currentTarget.dataset['id'];

    // Removing from dishes array
    const dishIndex = this.dishes.findIndex(dish => dish.id === dish_id);

    this.dishes.splice(dishIndex, 1);

    // Removing from table
    document.getElementById('tr-dish-' + dish_id).remove();

    // Hiding table and showing alert
    if ( this.dishes.length === 0 ) {
      this.tableTarget.classList.add('hidden');
      this.alertTarget.classList.remove('hidden');
    }
  }


  /*
   * _createRowForTable
   * Creates a new row in the table with the dish info
   * 
   */

  _createRowForTable (dish) {

    let tr = document.createElement('tr');
    let td1 = document.createElement('td');
    let td2 = document.createElement('td');

    let input = document.createElement('input');
    
    tr.setAttribute('id', 'tr-dish-' + dish.id);
    
    td1.setAttribute('class', 'px-6 py-4 whitespace-nowrap');

    if ( dish.image ) {
      td1.innerHTML = `
        <div class="flex items-center">
          <div class="flex-shrink-0 h-14 w-14">
            <img class="h-14 w-14" src="${dish.image}" alt="${dish.name_en}">
          </div>
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-900">
              <span class="font-bold">
                <a href="${dish.url}" target="_blank">
                  ${dish.name_en}                  
                </a>
              </span>
            </div>
          </div>
        </div>
      `;
    }
    else {
      td1.innerHTML = `
        <div class="flex items-center">
          <div class="flex-shrink-0 h-14 w-14">
            <span class="inline-block h-14 w-14 bg-gray-100 overflow-hidden">
              <svg class="h-full w-full text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
              </svg>
            </span>
          </div>
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-900">
              <span class="font-bold">
                <a href="${dish.url}" target="_blank">
                  ${dish.name_en}                  
                </a>
              </span>
            </div>
          </div>
        </div>
      `;
    }

    td2.setAttribute('class', 'px-6 py-4 whitespace-nowrap');
    td2.innerHTML = `
      <button type="button" class="btn" data-id="${dish.id}" data-action="click->dishes-list#removeDish">
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
        </svg>
      </button>
    `;

    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'menu[dish_ids][]');
    input.setAttribute('value', dish.id);
    input.setAttribute('id', `menu_dish_ids_${dish.id}`);

    tr.appendChild(input);
    tr.appendChild(td1);
    tr.appendChild(td2);

    this.bodyTarget.appendChild(tr);
  }


  /*
   * _fillDishesList
   * When editing a menu, we need to load the
   * dishes list in the beginning.
   *
   */

  _fillDishesList (dishesTableTows) {
    dishesTableTows.forEach((row) => {
      let dish_id = row.dataset['id'];

      this.dishes.push({
        id: dish_id,
        image: row.dataset['image'],
        url: row.dataset['url'],
        name_en: row.dataset['nameEn']
      });
    });
  }


  /*
   * _checkRepeatedDish
   * Returns if the given dish is already
   * stored in the dishes list
   * 
   */

  _checkRepeatedDish (dish_id) {
    for (let i = 0, j = this.dishes.length; i < j; i += 1) {
      if ( this.dishes[i].id === dish_id )
        return true
    }

    return false;
  }
}
