import { Controller } from "stimulus"

export default class extends Controller {

  checkboxes = null;
  
  connect() {

    this.checkboxes = document.querySelectorAll('input[id*="menu_menu_days_attributes_"]');

    //
    // Every time the daily checkbox changes its state
    // we check the states of the rest of checkboxes.
    //
    // If daily checkbox is enabled, the rest is disabled.
    //

    this.checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', this._changeCheckboxesState.bind(this));
    });
  }

  _changeCheckboxesState (event) {

    let hiddenDestroyInput = document.getElementById(event.target.id.replace(/day$/, '_destroy'));

    if ( event.target.checked ) {
      if ( event.target.id === 'menu_menu_days_attributes_0_day') {

        //
        // If we check 'daily', we disable the rest
        //

        this.checkboxes.forEach((checkbox) => {
          if ( checkbox.id !== 'menu_menu_days_attributes_0_day')
            checkbox.disabled = true;
          else
            checkbox.disabled = false;
        });
      }
      else {

        //
        // If we check a checkbox different from 'daily', we
        // disable 'daily'
        //

        this.checkboxes.forEach((checkbox) => {
          if ( checkbox.id === 'menu_menu_days_attributes_0_day') {
            checkbox.checked = false;
            checkbox.disabled = true;
          }
          else
            checkbox.disabled = false;
        });
      }

      // Changing _destroy field value
      if ( hiddenDestroyInput ) hiddenDestroyInput.value = 0;
    }
    else {
      if ( event.target.id === 'menu_menu_days_attributes_0_day' ) {

        //
        // if we uncheck 'daily', we enable the rest
        //

        this.checkboxes.forEach((checkbox) => {
          checkbox.disabled = false;
        });
      }
      else {

        //
        // If we uncheck a not 'daily' checkbox, we check if
        // there're more checkboxes enabled. If not, we enable
        // 'daily'.
        //

        for ( let i = 0, j = this.checkboxes.length; i < j; i += 1 ) {
          if ( this.checkboxes[i].id !== 'menu_menu_days_attributes_0_day' && this.checkboxes[i].checked )
            break;
          else {
            if ( i === (this.checkboxes.length - 1)) {
              // We reenable 'daily' as there're not more checkboxes enabled
              document.getElementById('menu_menu_days_attributes_0_day').disabled = false;
            }
          }

        }
      }

      // Changing _destroy field value
      if ( hiddenDestroyInput ) hiddenDestroyInput.value = 1;
    }
  }

}
