import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "slider" ]

  connect() {

  }

  selectFunction(event) {
    let e = event.target 
    let selected = document.getElementsByClassName("mobile_selected")[0]
    let newSelected = document.getElementById(e.dataset.id)
    let actualId = selected.id
    let newId = newSelected.id
    let actualPhone = document.getElementById("movil" + actualId.substring(actualId.length-1))
    let newPhone = document.getElementById("movil" + newId.substring(newId.length-1))

    selected.classList.remove('mobile_selected', 'border-l-2', 'border-r-2')
    selected.classList.add('mobile_no_selected')
    newSelected.classList.remove('mobile_no_selected')
    newSelected.classList.add('mobile_selected')
    if(newSelected.id == "function-1" || newSelected.id == "function-3" ){
      newSelected.classList.add('border-r-2')
    } else {
      newSelected.classList.add('border-l-2')
    }
    actualPhone.classList.add('hidden')
    newPhone.classList.remove('hidden')

  }

}
