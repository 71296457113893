import { Controller } from 'stimulus';

/*
 * Ingredients allergens managing controller
 *
 * Add, remove and list action.
 * 
 */

export default class extends Controller {

	static targets = [
    'allergensMode',
    'allergensIngredientsListElement',
    'hiddenAllergensAuto',
    'allergensAlert'
  ];

  _allergensContainerListElement = null;

	connect () {

    // Allergens list
    this._allergensContainerListElement = document.getElementById('allergens_selector');

    this._getIngredientsController((controller) => {

      if ( controller.actionValue === 'create') {
        // We're creating a new dish
        // Automatically mode for allergens enabled by default
        this._allergensContainerListElement.classList.add('hidden');
      }
      else {
        // We're editing a dish
        if ( this.allergensModeTarget.value === 'true' ) {
          this._allergensContainerListElement.classList.add('hidden');
          this.allergensIngredientsListElementTarget.classList.remove('hidden');
        }
        else {
          this._allergensContainerListElement.classList.remove('hidden');
          this.allergensIngredientsListElementTarget.classList.add('hidden');
        }
      }

    });
	}

  /*
   * changeMode
   * Changes the way allergens are managed or listed.
   * Could be automatically or manually.
   *
   */

  changeMode (event) {
    if ( event.target.checked ) {
      // Automatically
      // Hiding the allergens list
      this._allergensContainerListElement.classList.add('hidden');
      this.allergensIngredientsListElementTarget.classList.remove('hidden');

      this._getIngredientsController((controller) => {
        if ( controller.ingredients.length > 0 )
          this.allergensAlertTarget.classList.add('hidden');
        else
          this.allergensAlertTarget.classList.remove('hidden');

        this.hiddenAllergensAutoTarget.value = true;
      });
    }
    else {
      // Manually
      // Showing allergens default list and hiding allergens ingredients list
      this._allergensContainerListElement.classList.remove('hidden');
      this.allergensIngredientsListElementTarget.classList.add('hidden');
      this.allergensAlertTarget.classList.add('hidden');

      this.hiddenAllergensAutoTarget.value = false;
    }
  }


  addAllergen (allergen) {

    let div = document.createElement('div');

    div.setAttribute('class', 'relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex flex-col items-center hover:border-blue-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500');
    div.setAttribute('id', 'allergen-' + allergen.id);

    let html1 = `<div>`;

    let html2 = null;

    if ( allergen.image ) {      
      html2 = `
        <img class="h-14 w-14 rounded-full" src="__image__" alt="">
      `
      .replace('__image__', allergen.image);;
    }
    else {
      html2 = `
        <span class="inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-100">
          <svg class="h-full w-full text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path>
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
          </svg>
        </span>
      `;
    }

    let html3 = `
      </div>
      <div class="text-center">
        <p class="text-sm font-medium text-gray-900">
          __name__
        </p>
        <p class="text-xs text-gray-400" data-toggle-switch-target="text">
    `.replace('__name__', allergen.name);

    let html4 = null;

    if ( allergen.contain )
      html4 = `<div class="label label-warning">May contain</div>`;
    else
      html4 = `<div class="label label-success">Contain</div>`;

    let html5 = `
        </p>
      </div>
    </div>
    `;

    let html = html1 + html2 + html3 + html4 + html5;

    div.innerHTML = html;

    this.allergensIngredientsListElementTarget.appendChild(div);

    // Hiding no allergens alert
    this.allergensAlertTarget.classList.add('hidden');
  }


  removeAllergen (allergen) {
    document.getElementById('allergen-' + allergen.id).remove();

    this._getIngredientsController((controller) => {
      if ( controller.ingredients.length === 0 )
        this.allergensAlertTarget.classList.remove('hidden');
    });
  }


  /*
   * _getIngredientsController
   * Connects and returns the ingredients controller
   * 
   */

  _getIngredientsController (cb) {

    let controller = null;

    let stop = setInterval(() => {
      if ( controller ) {
        clearInterval(stop);
        return cb(controller);
      }
      else {
        controller = this.application.controllers.find(controller => {
          let identifier = controller.context.identifier;
  
          if ( identifier === 'ingredients-list')
            return controller;
          else
            return null;
        });
      }
    }, 200);
  }

}