import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    let day = document.getElementById('day');
    let dailies = document.getElementsByClassName('daily');
    let toShow = 0;
    if ( day )
      toShow = document.getElementsByClassName(day.value);
    for (let i = 0; i < dailies.length; i++) {
      dailies[i].classList.remove('hidden');
    };
    for (let i = 0; i < toShow.length; i++) {
      toShow[i].classList.remove('hidden');
    };
  }

  pickDay(event) {
    let toHide = document.getElementsByClassName('single-menu');
    let dailies = document.getElementsByClassName('daily');
    let toShow = document.getElementsByClassName(event.target.value);
    for (let i = 0; i < toHide.length; i++) {
      toHide[i].classList.add('hidden');
    };
    for (let i = 0; i < dailies.length; i++) {
      dailies[i].classList.remove('hidden');
    };
    for (let i = 0; i < toShow.length; i++) {
      toShow[i].classList.remove('hidden');
    };
  }

}