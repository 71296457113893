import { Controller } from 'stimulus';

/*
 * File Edition controller
 * Manages the files uploaded in
 * a dropzone space
 *
 */

export default class extends Controller {

	static targets = [ 'file' ];

	connect () {}

	removeFile (event) {

    let fileId = event.currentTarget.dataset['id'];

		this.fileTarget.remove();

    let fileContainer = document.getElementById('files_container');

    let input = document.createElement('input');

    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'ingredient[remove_files][id]'.replace('id', fileId));
    input.setAttribute('value', true);
    input.setAttribute('id', 'ingredient_remove_files_id_' + fileId);

    fileContainer.append(input);
	}

}