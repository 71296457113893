import { Controller } from "stimulus"
//import { allMeasures } from 'convert-units';
//import configureMeasurements from 'convert-units';
import { create, all } from 'mathjs'

/*
 * Ingredients lists managing controller
 *
 * Add, remove and list action.
 * 
 */

export default class extends Controller {

  ingredients = [];
  allergens = [];
  additional_informations = [];

  _allergensController = null;

  static targets = [
    'alert',
    'table',
    'body',
    'valuesNutritional',
    'tableNutritional',
    'energyTd',
    'proteinsTd',
    'carboTd',
    'sugarsTd',
    'fatsTd',
    'saturatedFatsTd',
    'saltTd',
    'estimatedCost'
  ];

  static values = {
    action: String,
    ingredientsNumber: Number
  };

  
  connect() {
    
    if ( this.actionValue === 'create' ) {

      // We're creating a new dish
      // We hide the table until we add the first ingredient
      this.tableTarget.classList.add('hidden');
      this.valuesNutritionalTarget.classList.add('hidden');
    }
    else {
      // We're editing a dish
      if ( this.ingredientsNumberValue > 0 ) {
        this.alertTarget.classList.add('hidden');

        // Creating ingredients list at startup
        this._fillIngredientsList(document.querySelectorAll('tr[id*="tr-ingredient-"]'));
      }
      else {
        this.tableTarget.classList.add('hidden');
        this.valuesNutritionalTarget.classList.add('hidden');
      }
    }

  }


  /*
   * addIngredient
   * Adds a new ingredient to the list.
   * Creates a row in the table.
   * Creates events for gross amount and waste inputs.
   * Updates nutritional values.
   * 
   */

  addIngredient (event) {

    let ingredient_id = event.target.dataset['id'];

    if ( this._checkRepeatedIngredient(ingredient_id) ) return;

    this.ingredients.push({
      id: ingredient_id,
      name_en: document.getElementById('ingredient-' + ingredient_id + '-name_en').innerText,
      unit: event.target.dataset['unit'],
      energy: event.target.dataset['energy'],
      proteins: event.target.dataset['proteins'],
      carbohydrates: event.target.dataset['carbohydrates'],
      sugars: event.target.dataset['sugars'],
      fats: event.target.dataset['fats'],
      saturated_fats: event.target.dataset['saturatedFats'],
      salt: event.target.dataset['salt'],
      cost: event.target.dataset['cost'],
      ref_unit: event.target.dataset['refUnit'],
      ref_amount: event.target.dataset['refAmount'],
      url: event.target.dataset['url'],
      allergens: this._processAllergens(event.target.dataset['allergens']),
      additional_informations: this._processAdditionalInfo(event.target.dataset['additionalInfo'])
    });

    this._createRowForTable(this.ingredients[this.ingredients.length - 1]);

    // Updating the nutritional values
    this._updateNutritionalValues();

    if ( this.ingredients.length > 0 ) {
      this.alertTarget.classList.add('hidden');
      this.tableTarget.classList.remove('hidden');
      this.valuesNutritionalTarget.classList.remove('hidden');
    }
  }


  /*
   * removeIngredient
   * Removes an ingredient from the list
   * 
   */

  removeIngredient (event) {
    
    let ingredient_id = event.currentTarget.dataset['id'];

    // Deleting the item from ingredients array
    for (let i = 0, j = this.ingredients.length; i < j; i += 1) {
      if ( this.ingredients[i].id === ingredient_id ) {

        // In case of dish editing, we add a _destroy hidden field
        if ( this.actionValue === 'edit' ) {
          let input = document.createElement('input');

          input.setAttribute('type', 'hidden');
          input.setAttribute('id', 'dish_ingredients_lists_attributes_id_row__destroy'.replace('id_row', ingredient_id))
          input.setAttribute('name', 'dish[ingredients_lists_attributes][id_row][_destroy]'.replace('id_row', ingredient_id));
          input.setAttribute('value', true);

          this.bodyTarget.appendChild(input);
        }

        let allergens = this.ingredients[i].allergens;
        let additional_informations = this.ingredients[i].additional_informations;

        // Removing ingredient from list
        this.ingredients.splice(i, 1);
        
        // Updating allergens list
        this._removeAllergens(allergens);

        // Updating additional info list
        this._removeAdditionalInfos(additional_informations);

        break;
      }
    }

    // Deleting row in table
    document.getElementById('tr-ingredient-' + ingredient_id).remove();

    // Hiding table and showing alert
    if ( this.ingredients.length === 0 ) {
      this.tableTarget.classList.add('hidden');
      this.valuesNutritionalTarget.classList.add('hidden');
      this.alertTarget.classList.remove('hidden');
    }

    // Updating the nutritional values
    this._updateNutritionalValues();
  }

	showIngredients (event) {
    let input = document.getElementById('show-ingredients');
    input.toggleAttribute('checked');
  }

	showNutritional (event) {
    let input = document.getElementById('show-nutritional');
    input.toggleAttribute('checked');
  }


  /*
   * _checkRepeatedIngredient
   * Returns if the given ingredient is already
   * stored in the ingredients list
   * 
   */

  _checkRepeatedIngredient (ingredient_id) {
    for (let i = 0, j = this.ingredients.length; i < j; i += 1) {
      if ( this.ingredients[i].id === ingredient_id )
        return true
    }

    return false;
  }


  /*
   * _createRowForTable
   * Creates a new row in the table with the ingredient info
   * 
   */

  _createRowForTable (ingredient) {

    let mSec = new Date().getTime();

    let tr = document.createElement('tr');
    let td1 = document.createElement('td');
    let td2 = document.createElement('td');
    let td3 = document.createElement('td');
    let td4 = document.createElement('td');
    let td5 = document.createElement('td');
    let td6 = document.createElement('td');
    let input = document.createElement('input');
    let input2 = document.createElement('input');

    tr.setAttribute('id', 'tr-ingredient-' + ingredient.id);

    td1.setAttribute('class', 'px-6 py-4 whitespace-nowrap');
    
    td2.setAttribute('class', 'px-6 py-4 whitespace-nowrap');
    td2.setAttribute('id', 'gross_amount_' + mSec);

    td3.setAttribute('class', 'px-6 py-4 whitespace-nowrap');
    
    td4.setAttribute('class', 'px-6 py-4 whitespace-nowrap text-gray-500');
    td4.setAttribute('id', 'net_amount_' + mSec);

    td5.setAttribute('class', 'px-6 py-4 whitespace-nowrap text-gray-500');
    td5.setAttribute('id', 'cost_' + mSec);

    td6.setAttribute('class', 'px-6 py-4 whitespace-nowrap text-right');

    td1.innerHTML = `
      <span class="font-bold">
        <a href="${ingredient.url}" target="_blank">
          ${ingredient.name_en}
        </a>
      </span>
    `;

    td2.innerHTML = `
      <div class="max-w-lg">
        <div class="flex flex-col">
          <div class="flex flex-row">
            <div class="flex rounded-md shadow-sm">
              <input type="hidden" name="dish[ingredients_lists_attributes][id_row][gross_amount_note]" id="dish_ingredients_lists_attributes_id_row_gross_amount_note">
              <input type="hidden" name="dish[ingredients_lists_attributes][id_row][gross_amount_unit]" id="dish_ingredients_lists_attributes_id_row_gross_amount_unit">
              <input type="number" class="flex-1 form-control info" name="dish[ingredients_lists_attributes][id_row][gross_amount]" id="dish_ingredients_lists_attributes_id_row_gross_amount" value="0.0" />
              <span class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                ${ingredient.unit}
              </span>
            </div>
            <div class="has-tooltip" data-controller="modal-gross-amount">
              <span class="tooltip">Create note</span>
              <button type="button" id="create_note_id_row" class="btn ml-2 h-full block" data-action="click->modal-gross-amount#open" data-modal-id="id_row" data-modal="gross_amount_notes">
                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
                </svg>
              </div>
            </div>
          </div>
          <div>
            <span class="text-xs text-gray-400" id="text_note_id_row"></span>
          </div>
        </div>
      </div>
    `.replaceAll('id_row', mSec);

    td3.innerHTML = `
      <div class="max-w-lg flex rounded-md shadow-sm">
        <input type="number" class="flex-1 form-control info" name="dish[ingredients_lists_attributes][id_row][waste]" id="dish_ingredients_lists_attributes_id_row_waste" value="0.0" />
        <span class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
          %
        </span>
      </div>
    `.replaceAll('id_row', mSec);

    td4.innerHTML = 0.0;
    td5.innerHTML = 0.0;
    td6.innerHTML = `
      <button type="button" class="btn" data-id="${ingredient.id}" data-action="click->ingredients-list#removeIngredient">
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
        </svg>
      </button>
    `;

    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'dish[ingredients_lists_attributes][id_row][ingredient_id]'.replace('id_row', mSec));
    input.setAttribute('value', ingredient.id);

    input2.setAttribute('type', 'hidden');
    input2.setAttribute('name', 'dish[ingredients_lists_attributes][id_row][cost]'.replace('id_row', mSec));
    input2.setAttribute('id', 'dish_ingredients_lists_attributes_id_row_cost'.replace('id_row', mSec));
    input2.setAttribute('value', 0.0);

    tr.appendChild(td1);
    tr.appendChild(td2);
    tr.appendChild(td3);
    tr.appendChild(td4);
    tr.appendChild(td5);
    tr.appendChild(td6);

    tr.appendChild(input);
    tr.appendChild(input2);

    this.bodyTarget.appendChild(tr);

    // Creating events for updating automatically the net amount field
    this._createEventsForRow(mSec, ingredient.cost, ingredient.ref_unit, ingredient.ref_amount, ingredient.unit);
  }


  /*
   * _createEventsForRow
   * Creates events for the gross amount and waste inputs
   * in order to calculate automatically the net amount
   * 
   */

  _createEventsForRow (msec_id, cost, ref_unit, ref_amount, unit) {
    let wasteInput = document.getElementById('dish_ingredients_lists_attributes_id_row_waste'.replace('id_row', msec_id))
    let grossAmountInput = document.getElementById('dish_ingredients_lists_attributes_id_row_gross_amount'.replace('id_row', msec_id))
    let netAmountTd = document.getElementById('net_amount_' + msec_id);
    let costTd = document.getElementById('cost_' + msec_id);

    grossAmountInput.addEventListener('input', (e) => {

      // Updating net amount column
      netAmountTd.innerText = parseFloat(grossAmountInput.value * (1 - (wasteInput.value/100))) + ` ${unit}`;

      // Units conversion
      let _cost = 0.0;

      if ( ref_unit && ref_amount ) {
        const math = create(all, {});
        const ref_value = math.unit(ref_amount, ref_unit);

        _cost = parseFloat((grossAmountInput.value * cost) / ref_value.toNumber(unit));
      }
      else
        _cost = parseFloat(grossAmountInput.value * cost);

      // Updating cost column
      let hiddenInput = document.getElementById(('dish_ingredients_lists_attributes_id_row_cost').replace('id_row', msec_id));
      
      hiddenInput.value = _cost;
      costTd.innerText = _cost  + ' €';

      // Updating estimated cost
      this._updateEstimatedCost();
    });

    wasteInput.addEventListener('input', (e) => {
      // Updating net amount column
      netAmountTd.innerText = parseFloat(grossAmountInput.value * (1 - (wasteInput.value/100))) + ` ${unit}`;
    });
  }


  /*
   * _updateEstimatedCost
   * Sum all the ingredients cost columns and update estimated cost
   * 
   */

  _updateEstimatedCost () {
    let costTds = document.querySelectorAll('td[id*="cost_"]');

    this.estimatedCostTarget.innerText = 0.0;

    costTds.forEach((td) => {
      let currentCost = parseFloat(td.innerText.replace('€', ''));
      this.estimatedCostTarget.innerText = (parseFloat(this.estimatedCostTarget.innerText.replace('€', '')) + currentCost) + ' €';
    });
  }


  /*
   * _updateNutritionalValues
   * Updates the nutritional values in the table according
   * to all the ingredients added in the list
   * 
   */

  _updateNutritionalValues () {

    let energy = 0.0;
    let proteins = 0.0;
    let carbo = 0.0;
    let sugars = 0.0;
    let fats = 0.0;
    let saturated_fats = 0.0;
    let salt = 0.0;

    this.ingredients.forEach((ingredient) => {
      energy += parseFloat(ingredient.energy);
      proteins += parseFloat(ingredient.proteins);
      carbo += parseFloat(ingredient.carbohydrates);
      sugars += parseFloat(ingredient.sugars);
      fats += parseFloat(ingredient.fats);
      saturated_fats += parseFloat(ingredient.saturated_fats);
      salt += parseFloat(ingredient.salt);
    });

    this.energyTdTarget.innerText = energy + ' kcal';
    this.proteinsTdTarget.innerText = proteins + ' g';
    this.carboTdTarget.innerText = carbo + ' g';
    this.sugarsTdTarget.innerText = sugars + ' g';
    this.fatsTdTarget.innerText = fats + ' g';
    this.saturatedFatsTdTarget.innerText = saturated_fats + ' g';
    this.saltTdTarget.innerText = salt + ' g';
  }


  /*
   * _processAllergens
   * Creates the allergens list without duplicated ones
   * 
   */

  _processAllergens (allergens_string) {

    let allergens =  JSON.parse(allergens_string).map((allergen) => {
      return JSON.parse(allergen);
    });

    // Updating general allergens list.
    // We avoid duplicated allergens.
    //
    // 'Found' value represents the this.allergens index
    // when an specific ingredient is found.
    let found = -1;

    for ( let x = 0, y = allergens.length; x < y; x += 1 ) {
      for ( let i = 0, j = this.allergens.length; i < j; i += 1) {
        if ( this.allergens[i].id === allergens[x].id ) {
          found = i;
          break;
        }
      }

      if ( found === -1 ) {

        // We use 'count' to count the number of ingredients
        // that belongs to this allergen.
        allergens[x].count = 1;

        this.allergens.push(allergens[x]);

        // Adding allergen to the allergens list as well
        this._getController('allergens-list', (controller) => {
          controller.addAllergen(allergens[x]);
        });
      }
      else {
        
        // Allergen already listed, we increase the
        // ingredients counter in the allergen.
        this.allergens[found].count += 1;

        found = -1;
      }
    }

    return allergens;
  }


  /*
   * _processAllergens
   * Creates the allergens list without duplicated ones
   * 
   */

  _processAdditionalInfo (additional_info_string) {
  
    let additional_informations =  JSON.parse(additional_info_string).map((additional_info) => {
      return JSON.parse(additional_info);
    });

    // Updating general additional infos list.
    // We avoid duplicated additional infos.
    //
    // 'Found' value represents the this.additional_infos index
    // when an specific ingredient is found.
    let found = -1;

    for ( let x = 0, y = additional_informations.length; x < y; x += 1 ) {
      for ( let i = 0, j = this.additional_informations.length; i < j; i += 1) {
        if ( this.additional_informations[i].id === additional_informations[x].id ) {
          found = i;
          break;
        }
      }

      if ( found === -1 ) {

        // We use 'count' to count the number of ingredients
        // that belongs to this allergen.
        additional_informations[x].count = 1;

        this.additional_informations.push(additional_informations[x]);

        // Adding additional info to the additional info list as well
        this._getController('additional-info-list', (controller) => {
          controller.addAdditionalInfo(additional_informations[x]);
        });
      }
      else {
        
        // Allergen already listed, we increase the
        // ingredients counter in the allergen.
        this.additional_informations[found].count += 1;

        found = -1;
      }
    }

    return additional_informations;
  }


  /*
   * _removeAllergens
   * After removing an ingredient, we gotta update
   * the allergens list.
   * 
   */

  _removeAllergens (allergens) {

    // Looping in the ingredient allergens marked for removing
    allergens.forEach((allergen) => {
      
      // Removing the allergen from the list
      for ( let i = 0, j = this.allergens.length; i < j; i += 1 ) {
        if ( this.allergens[i].id.toString() === allergen.id.toString() ) {

          this.allergens[i].count -= 1;
          
          // If allergen count is 0, we remove the allergen
          // because there're not more ingredients with
          // this allergen.

          if ( this.allergens[i].count === 0 ) {
            // Removing the allergen from the screen
            this._getController('allergens-list', (controller) => {
              controller.removeAllergen(allergen);

              if ( this.allergens.length > 1 )
                this.allergens.splice(i, 1);
              else
                this.allergens = [];
            });
          }
          
          break;
        }
      }

    });

  }


  /*
   * _removeAdditionalInfos
   * After removing an ingredient, we gotta update
   * the additional infos list.
   * 
   */

  _removeAdditionalInfos (additional_informations) {

    // Looping in the ingredient additional infos marked for removing
    additional_informations.forEach((additional_info) => {
      
      // Removing the allergen from the list
      for ( let i = 0, j = this.additional_informations.length; i < j; i += 1 ) {
        if ( this.additional_informations[i].id.toString() === additional_info.id.toString() ) {

          this.additional_informations[i].count -= 1;
          
          // If additional info count is 0, we remove the additional info
          // because there're not more ingredients with
          // this allergen.

          if ( this.additional_informations[i].count === 0 ) {
            // Removing the allergen from the screen
            this._getController('additional-info-list', (controller) => {
              controller.removeAdditionalInfo(additional_info);

              if ( this.additional_informations.length > 1 )
                this.additional_informations.splice(i, 1);
              else
                this.additional_informations = [];
            });
          }
          
          break;
        }
      }

    });

  }


  /*
   * _getController
   * Connects and returns the given controller
   * 
   */

  _getController (controller_name, cb) {

    let controller = null;

    let stop = setInterval(() => {
      if ( controller ) {
        clearInterval(stop);
        return cb(controller);
      }
      else {
        controller = this.application.controllers.find(controller => {
          let identifier = controller.context.identifier;
  
          if ( identifier === controller_name)
            return controller;
          else
            return null;
        });
      }
    }, 200);
  }


  /*
   * _fillIngredientsList
   * When editing a dish, we need to load the
   * ingredients list in the beginning.
   *
   */

  _fillIngredientsList (ingredients_table_rows) {
    ingredients_table_rows.forEach((row) => {

      let ingredient_id = row.dataset['id'];

      this.ingredients.push({
        id: ingredient_id,
        name_en: document.getElementById('ingredient-list-' + ingredient_id + '-name_en').innerText,
        unit: row.dataset['unit'],
        energy: row.dataset['energy'],
        proteins: row.dataset['proteins'],
        carbohydrates: row.dataset['carbohydrates'],
        sugars: row.dataset['sugars'],
        fats: row.dataset['fats'],
        saturated_fats: row.dataset['saturatedFats'],
        salt: row.dataset['salt'],
        cost: row.dataset['cost'],
        ref_unit: row.dataset['refUnit'],
        ref_amount: row.dataset['refAmount'],
        url: row.dataset['url'],
        allergens: this._processAllergens(row.dataset['allergens']),
        additional_informations: this._processAdditionalInfo(row.dataset['additionalInfo'])
      });

      let lastIngredient = this.ingredients[this.ingredients.length - 1];

      this._createEventsForRow(ingredient_id, lastIngredient.cost, lastIngredient.ref_unit, lastIngredient.ref_amount, lastIngredient.unit);
    });

    this._updateNutritionalValues();
    this._updateEstimatedCost();
  }

}
